import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Sideimg from "../../../images/reference/snowboard-zezula/brand-image.jpg"
import Top from "../../../images/reference/snowboard-zezula/top.jpg"
import Logo from "../../../images/reference/snowboard-zezula/logo.png"
import Header from "../../../images/reference/snowboard-zezula/header-marketing.png"
import Martin from "../../../images/reference/snowboard-zezula/reaction-marketing.png"

class Snowboard extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(10,75,54,0.9),rgba(0,0,0,0.9)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>
            Reference na marketingové řešení pro Snowboard Zezula | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy.  Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          extodkaz="//snowboard-zezula.cz/"
          heading="Reference na marketingové řešení pro Snowboard Zezula"
          odstavec="září 2010 - současnost"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Snowboard Zezula je specializovaný prodejce snowboardového vybavení a doplňků, který nabízí nejširší sortiment snowboardového vybavení v ČR."
            secondaryText="Od roku 1996 provozují prodejnu v Brně a od roku 2004 také e-shop. Jako jedni z prvních pochopili důležitost internetových obchodů a dělají vše pro to, aby zůstali jedničkou jakožto prodejci i výrobci snowboardů."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p className="text-center">
                Převzít správu kampaní, řešení SEO a další propagace pro
                snowboard-zezula.cz.
              </p>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <ul>
                <li>správa PPC kampaní (Google AdWords a Sklik)</li>
                <li>správa facebookových reklam</li>
                <li>konzultace marketingové strategie</li>
                <li>optimalizace XML feedů pro zbožové vyhledávače</li>
              </ul>
            </PaddingWrapper>
          </HalfWidthBlock>

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Sideimg}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Historie spolupráce</TitleH2>

              <p>
                Na Snowboard Zezula jsme začali pracovat původně jako vývojářská
                výpomoc, velmi brzy jsme ale převzali kompletní vývoj. V září
                2010 jsme začali s prvními marketingovými střípky a začali řešit
                drobnosti v SEO, XML feedy, konzultace Facebooku a Twitteru.
                Následně jsme vyzkoušeli zefektivnit kampaně pro české AdWords a
                když se to výrazně podařilo, začali jsme spravovat i slovenskou
                verzi a Sklik kampaně. V současné době již pro Snowboard Zezula
                v oblasti on-line marketingu řešíme vše.
              </p>
            </BlockImageTextHalf>
          </PaddingWrapper>

          <Medailon
            wide
            image={Martin}
            alt=""
            text="Na igloonetu se mi líbí spojení profesionality s neformálností. Abych řekl pravdu, tak jste nám vytrhli trn z paty."
            name="Martin Zezula"
            position="ředitel"
          />
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Snowboard
